"use client";

import * as React from "react";
import * as SeparatorPrimitive from "@radix-ui/react-separator";

import { cn } from "@/lib/utils";

interface SeparatorProps
    extends React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root> {
    text?: string;
}

const Separator = React.forwardRef<
    React.ElementRef<typeof SeparatorPrimitive.Root>,
    SeparatorProps
>(
    (
        {
            className,
            orientation = "horizontal",
            decorative = true,
            text,
            ...props
        },
        ref
    ) => (
        <div
            className={cn(
                "relative",
                orientation === "horizontal"
                    ? "flex items-center"
                    : "flex flex-col items-center",
                className
            )}
        >
            {orientation === "horizontal" && (
                <>
                    <div className="border-t grow border-zinc-300"></div>
                    {text && (
                        <span className="mx-3 text-sm leading-8 shrink text-zinc-500">
                            {text}
                        </span>
                    )}
                    <div className="border-t grow border-zinc-300"></div>
                </>
            )}
            {orientation === "vertical" && (
                <>
                    <div className="border-l grow border-zinc-300"></div>
                    {text && (
                        <span className="mx-3 text-sm leading-8 rotate-90 shrink text-zinc-500">
                            {text}
                        </span>
                    )}
                    <div className="border-l grow border-zinc-300"></div>
                </>
            )}
            <SeparatorPrimitive.Root
                ref={ref}
                decorative={decorative}
                orientation={orientation}
                className={cn(
                    "absolute",
                    orientation === "horizontal"
                        ? "left-0 right-0 top-1/2 -translate-y-1/2 h-[1px] w-full"
                        : "top-0 bottom-0 left-1/2 -translate-x-1/2 h-full w-[1px]",
                    className
                )}
                {...props}
            />
        </div>
    )
);

Separator.displayName = SeparatorPrimitive.Root.displayName;

export { Separator };
